import { list_resellers } from "actions/adminResellerAction";
import ResellerCardTable from "components/admin/ResellerCardTable";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const AdminResellers = () => {
  const { resellers, loading } = useSelector((state) => state.adminResellers);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(list_resellers());
  }, [dispatch]);

  const props = {
    resellers,
    loading,
    name: "Resellers",
    link: "/master/reseller/new",
  };
  return (
    <>
      <div className="bg-light-blue-500 pt-14 pb-28 px-3 md:px-8 h-auto">
        <div className="container mx-auto max-w-full"></div>
      </div>

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <ResellerCardTable {...props} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminResellers;
