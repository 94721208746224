import { combineReducers } from "redux";
import adminAuthReducer from "./adminAuthReducer";
import AdminResellerReducer from "./adminResellerReducer";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import flashMessages from "./flashMessages";
import memberReducer from "./memberReducer";
import paymentReducer from "./paymentReducer";
import walletReducer from "./walletReducer";

export default combineReducers({
  adminAuth: adminAuthReducer,
  auth: authReducer,
  adminResellers: AdminResellerReducer,
  wallet: walletReducer,
  members: memberReducer,
  payments: paymentReducer,
  flash: flashMessages,
  errors: errorReducer,
});
