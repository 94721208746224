import { ADMIN_GET_RESELLER } from "actions/actionTypes";
import { ADMIN_RESELLER_LOADING } from "actions/actionTypes";
import { ADMIN_UPDATE_RESELLER } from "actions/actionTypes";
import { ADMIN_DELETE_RESELLER } from "actions/actionTypes";
import { ADMIN_CREATE_RESELLER } from "actions/actionTypes";
import { ADMIN_LIST_ALL_RESELLERS } from "actions/actionTypes";

const initialState = {
  resellers: [],
  reseller: [],
  loading: false,
};

const AdminResellerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_LIST_ALL_RESELLERS:
      return {
        ...state,
        resellers: action.payload,
        loading: false,
      };
    case ADMIN_CREATE_RESELLER:
      return {
        ...state,
        resellers: [action.payload, ...state.resellers],
        reseller: action.payload,
        loading: false,
      };
    case ADMIN_GET_RESELLER:
      const dex = state.resellers.find((item) => item.id === action.payload.id);
      if (dex > -1) {
        return {
          ...state,
          resellers: [...state.resellers],
          reseller: dex,
          loading: false,
        };
      } else {
        return {
          ...state,
          resellers: [action.payload, ...state.resellers],
          reseller: action.payload,
          loading: false,
        };
      }
    case ADMIN_UPDATE_RESELLER:
      const updatedItems = state.resellers.map((item) => {
        if (item.id === action.payload.id) {
          return { ...action.payload };
        }
        return item;
      });
      return {
        ...state,
        resellers: updatedItems,
        reseller: action.payload,
        loading: false,
      };
    case ADMIN_DELETE_RESELLER:
      return {
        ...state,
        resellers: state.resellers.filter((item) => item.id !== action.id),
        loading: false,
      };
    case ADMIN_RESELLER_LOADING:
      return {
        ...state,
        loading: action.payload === "false" ? false : true,
      };
    default:
      return state;
  }
};

export default AdminResellerReducer;
