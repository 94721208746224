import StatusCard from "components/StatusCard";
import SettingsForm from "components/SettingsForm";
import ProfileCard from "components/ProfileCard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { walletCredits } from "actions/paymentAction";
import Loader from "components/Loader";
import { useState } from "react";
import { setMemberLoading } from "actions/memberActions";
import apiClient from "utils/apiClient";

export default function Dashboard() {
  const { walletAmount, loading } = useSelector((state) => state.wallet);
  const memberLoading = useSelector((state) => state.members.loading);
  const [memberCount, setMemberCount] = useState("0");
  const [memberCountWeek, setMemberCountWeek] = useState("0");
  const [memberCountMonth, setMemberCountMonth] = useState("0");

  const dispatch = useDispatch();

  const member_count = () => {
    dispatch(setMemberLoading());
    const url = "/api/members/all/count";
    apiClient()
      .get(url)
      .then((res) => {
        setMemberCount(res.data.all);

        setMemberCountMonth(res.data.month);

        setMemberCountWeek(res.data.week);
      })
      .catch((err) => console.log(err.response.data));
    dispatch(setMemberLoading("false"));
  };

  useEffect(() => {
    dispatch(walletCredits());
    member_count();
  }, [dispatch]);

  let setAmount;
  if (loading) {
    setAmount = <Loader />;
  } else {
    setAmount = walletAmount != "" ? `$${walletAmount}` : "0";
  }
  console.log(walletAmount);
  return (
    <>
      <div className="bg-light-blue-500 pt-14 pb-28 px-3 md:px-8 h-auto">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4">
            <StatusCard
              color="pink"
              icon="trending_up"
              title="Users"
              amount={memberLoading ? <Loader /> : memberCount.toString()}
              percentage=""
              percentageIcon=""
              percentageColor="green"
              date="Total till now"
            />
            <StatusCard
              color="orange"
              icon="groups"
              title="New Users"
              amount={memberLoading ? <Loader /> : memberCountMonth.toString()}
              percentage=""
              percentageIcon=""
              percentageColor="red"
              date="Since last month"
            />
            <StatusCard
              color="blue"
              icon="poll"
              title="New Users"
              amount={memberLoading ? <Loader /> : memberCountWeek.toString()}
              percentage=""
              percentageIcon=""
              percentageColor="green"
              date="This week"
            />
            <StatusCard
              color="purple"
              icon="paid"
              title="Dollar Credits"
              amount={setAmount}
              percentage=""
              percentageIcon=""
              percentageColor="orange"
              date="Total dollars remaining"
            />
          </div>
        </div>
      </div>

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 xl:grid-cols-6">
            <div className="xl:col-start-1 xl:col-end-5 px-4 mb-16">
              <SettingsForm />
            </div>
            <div className="xl:col-start-5 xl:col-end-7 px-4 mb-16 mt-14">
              <ProfileCard walletAmount={walletAmount} loading={loading} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
