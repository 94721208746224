import { RESET_ERRORS } from "actions/actionTypes";
import { GET_ERRORS } from "actions/actionTypes";

const initialState = {};

const errorReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      return action.payload;
    case RESET_ERRORS:
      return {};
    default:
      return state;
  }
};

export default errorReducer;
