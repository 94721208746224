import {
  ADD_FLASH_MESSAGE,
  DELETE_FLASH_MESSAGE,
  RESET_ERRORS,
} from "./actionTypes";

export const addFlashMessages = (message) => {
  return {
    type: ADD_FLASH_MESSAGE,
    message,
  };
};

export const clearFlashMessages = () => {
  return {
    type: DELETE_FLASH_MESSAGE,
  };
};

export const resetErrors = () => {
  return {
    type: RESET_ERRORS,
  };
};
