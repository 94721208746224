import { Route, Redirect } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";

const AdminPrivateRoute = ({ component: Component, ...rest }) => {
  const Auth = useSelector((state) => state.adminAuth);

  return (
    <Route
      {...rest}
      render={(props) =>
        Auth.isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/master" />
        )
      }
    />
  );
};

AdminPrivateRoute.propTypes = {
  Auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  Auth: state.adminAuth,
});

export default connect(mapStateToProps)(AdminPrivateRoute);
