import Card from "@material-tailwind/react/Card";
import H3 from "@material-tailwind/react/Heading3";
import { Link } from "react-router-dom";
import Loader from "./Loader";

export default function ProfileCard(props) {
  const { walletAmount, loading } = props;

  let setAmount;
  if (loading) {
    setAmount = <Loader />;
  } else {
    setAmount = walletAmount ? `$${walletAmount}` : "0";
  }

  return (
    <Card>
      <div className="flex flex-wrap justify-center">
        <div className="w-48 px-4 -mt-24">
          <span className="material-icons dollar_icon">paid</span>
        </div>
        <div className="w-full flex justify-center py-4 lg:pt-4 pt-8">
          <div className="p-4 text-center">
            <H3 color="gray">{setAmount}</H3>
            <span className="text-sm text-gray-700" style={{ color: "brown" }}>
              Credits available
            </span>
            {walletAmount && walletAmount < 50 && walletAmount > 0 ? (
              <p
                style={{ color: "red", fontStyle: "italic", fontSize: "13px" }}
              >
                You are running out of credits, please recharge
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="text-center">
        <div className="mt-0 mb-2 text-gray-700 flex items-center justify-center gap-2">
          <Link
            to="/payments/new"
            className="false flex items-center justify-center gap-1 font-bold outline-none uppercase tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-2.5 px-6 text-xs leading-normal text-white bg-purple-500 hover:bg-purple-700 focus:bg-purple-400 active:bg-purple-800 shadow-md-purple hover:shadow-lg-purple"
          >
            Buy More!
          </Link>
        </div>
      </div>
    </Card>
  );
}
