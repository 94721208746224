import { RESELLER_AUTH_LOADING } from "actions/actionTypes";
import { SET_CURRENT_RESELLER } from "actions/actionTypes";
import isEmpty from "utils/isEmpty";

const initialState = {
  isResellerAuthenticated: false,
  reseller: {},
  loading: false,
};

const authReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_RESELLER:
      return {
        ...state,
        isResellerAuthenticated: !isEmpty(action.payload),
        reseller: action.payload,
        loading: false,
      };
    case RESELLER_AUTH_LOADING:
      return {
        ...state,
        loading: action.payload === "false" ? false : true,
      };
    default:
      return state;
  }
};

export default authReducer;
