import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Button from "@material-tailwind/react/Button";
import Input from "@material-tailwind/react/Input";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { update_reseller } from "actions/authActions";
import Loader from "./Loader";
//import { clearFlashMessages } from "actions/flashMessagesAction";
import FlashMessageList from "./flash/FlashMessageList";

export default function SettingsForm() {
  const { reseller, loading } = useSelector((state) => state.auth);
  const errorsSubmission = useSelector((state) => state.errors);
  const { flash } = useSelector((state) => state.flash);

  const [resellerId, setResellerId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [phone, setPhone] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [errors, setErrors] = useState({});
  const [flashMsg, setFlashMsg] = useState([]);

  const dispatch = useDispatch();
  // const clearFlash = () => {
  //   setTimeout(() => {
  //     dispatch(clearFlashMessages());
  //     setFlashMsg([]);
  //   }, 20000);
  // };

  useEffect(() => {
    if (reseller.id) {
      setResellerId(reseller.id);
      setName(reseller.name);
      setEmail(reseller.email);
      setPhone(reseller.phone);
      setState(reseller.state);
      setCity(reseller.city);
      setAddress(reseller.address);
    }
    if (errorsSubmission) {
      setErrors(errorsSubmission);
    }
    if (flash && flash.length > 0) {
      setFlashMsg(flash);
      //clearFlash();
    }
  }, [
    reseller.id,
    reseller.name,
    reseller.email,
    reseller.phone,
    reseller.state,
    reseller.city,
    reseller.address,
    errorsSubmission,
    flash,
    //clearFlash,
  ]);

  let loader;
  if (loading) {
    loader = <Loader />;
  }

  const saveForm = (e) => {
    e.preventDefault();

    const data = {
      id: resellerId,
      name,
      email,
      password,
      password_confirmation,
      phone,
      state,
      city,
      address,
    };
    dispatch(update_reseller(data));
  };

  return (
    <Card>
      <CardHeader color="purple" contentPosition="none">
        <div className="w-full flex items-center justify-between">
          <h2 className="text-white text-2xl">My Account</h2>
          <Button
            color="transparent"
            buttonType="link"
            size="lg"
            style={{ padding: 0 }}
          >
            Settings
          </Button>
        </div>
      </CardHeader>
      <CardBody>
        {flashMsg && (
          <FlashMessageList
            messages={
              Array.isArray(flashMsg)
                ? flashMsg.filter(
                    (f) => f.eventFor === "reseller_account_setting_update"
                  )
                : {}
            }
          />
        )}
        <form onSubmit={saveForm}>
          <input type="hidden" name="id" value={resellerId} />

          <h6 className="text-purple-500 text-sm mt-3 mb-6 font-light uppercase">
            User Information
          </h6>
          <div className="flex flex-wrap mt-10">
            <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="Name"
                value={name ? name : ""}
                onChange={(e) => setName(e.target.value)}
                error={
                  typeof errors.errors !== "undefined" &&
                  typeof errors.errors.name !== "undefined"
                    ? errors.errors.name[0]
                    : ""
                }
              />
            </div>
            <div className="w-full lg:w-6/12 pl-4 mb-10 font-light">
              <Input
                type="email"
                color="purple"
                placeholder="Email Address"
                value={email ? email : ""}
                onChange={(e) => setEmail(e.target.value)}
                error={
                  typeof errors.errors !== "undefined" &&
                  typeof errors.errors.email !== "undefined"
                    ? errors.errors.email[0]
                    : ""
                }
              />
            </div>
            <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="New Password"
                value={password ? password : ""}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="w-full lg:w-6/12 pl-4 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="Confirm New Password"
                value={password_confirmation ? password_confirmation : ""}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
              />
            </div>
          </div>

          <h6 className="text-purple-500 text-sm my-6 font-light uppercase">
            Contact Information
          </h6>
          <div className="flex flex-wrap mt-10">
            <div className="w-full lg:w-12/12 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="Address"
                value={address ? address : ""}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="w-full lg:w-4/12 pr-4 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="Phone"
                value={phone ? phone : ""}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="w-full lg:w-4/12 px-4 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="City"
                value={city ? city : ""}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div className="w-full lg:w-4/12 pl-4 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="State"
                value={state ? state : ""}
                onChange={(e) => setState(e.target.value)}
              />
            </div>
          </div>

          {/* <h6 className="text-purple-500 text-sm my-6 font-light uppercase">
            About Me
          </h6> */}
          {/* <div className="flex flex-wrap mt-10 font-light">
            <Textarea
              color="purple"
              placeholder="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div> */}
          <Button
            color="purple"
            buttonType="filled"
            size="regular"
            rounded={false}
            block={false}
            iconOnly={false}
            ripple="light"
          >
            Save
          </Button>
          {loader && loader}
        </form>
      </CardBody>
    </Card>
  );
}
