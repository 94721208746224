import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import NewPaymentForm from "components/payment/NewPaymentForm";
import React from "react";
//const stripePromise = loadStripe("pk_test_LAcxOBKlms8yZASEIg4qCkpZ");
const stripePromise = loadStripe(
  "pk_live_51JY1G2GX5qZasameJBDy8WJLsoIO36bAxXdSCUtNkcyGHiNX3MftCkBQYQjrjRQuYEb7cD5kh8udZ1PHkoaRRxAU00FYEdn3Ea"
);
const NewPayment = () => {
  return (
    <>
      <div className="bg-light-blue-500 pt-14 pb-28 px-3 md:px-8 h-auto">
        <div className="container mx-auto max-w-full"></div>
      </div>

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full px-9">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Elements stripe={stripePromise}>
              <NewPaymentForm />
            </Elements>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPayment;
