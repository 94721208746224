import apiClient from "utils/apiClient";
import {
  ADD_WALLET_AMOUNT,
  ADMIN_LIST_PAYMENTS,
  CREATE_PAYMENT,
  GET_ERRORS,
  GET_WALLET_AMOUNT,
  LIST_PAYMENTS,
  PAYMENT_LOADING,
  UPDATE_PAYMENT,
  WALLET_LOADING,
} from "./actionTypes";

import { addFlashMessages } from "./flashMessagesAction";

export const list_payments = () => (dispatch) => {
  dispatch(setPaymentLoading());

  apiClient()
    .get("/api/payments/all")
    .then((res) => {
      dispatch({
        type: LIST_PAYMENTS,
        payload: res.data.results,
      });
    })
    .catch((err) => {
      typeof err.response !== "undefined" &&
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      dispatch(setPaymentLoading("false"));
    });
};

export const list_admin_payments = () => (dispatch) => {
  dispatch(setPaymentLoading());

  apiClient()
    .get("/api/admin/payments/all")
    .then((res) => {
      dispatch({
        type: ADMIN_LIST_PAYMENTS,
        payload: res.data.results,
      });
    })
    .catch((err) => {
      typeof err.response !== "undefined" &&
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      dispatch(setPaymentLoading("false"));
    });
};

export const create_payment = (data, history) => (dispatch) => {
  const { amount, result, sigImage, state, city, address, ip } = data;
  if (result.error) {
    // show error in payment form
    dispatch({ type: GET_ERRORS, payload: result.error });
    dispatch(setPaymentLoading("false"));
  } else {
    apiClient()
      .post(`/api/payments/create`, {
        payment_method_id: result.paymentMethod.id,
        name: result.paymentMethod.billing_details.name,
        email: result.paymentMethod.billing_details.email,
        amount,
        lastFour: result.paymentMethod.card.last4,
        brand: result.paymentMethod.card.brand,
        state,
        city,
        address,
        ip,
        sigImage,
      })
      .then(async (res) => {
        console.log(res.data);
        dispatch({
          type: CREATE_PAYMENT,
          payload: res.data.result,
        });
        dispatch({
          type: ADD_WALLET_AMOUNT,
          payload: res.data.amount,
        });
        dispatch(
          addFlashMessages({
            type: "success",
            eventFor: "reseller_payment",
            text: res.data.message,
          })
        );
        history.push("/payments");
      })
      .catch((err) => {
        console.log(err.response);
        typeof err.response !== "undefined" &&
          dispatch({ type: GET_ERRORS, payload: err.response.data });
        dispatch(setPaymentLoading("false"));
      });
  }
};

export const archive_payment = (data) => (dispatch) => {
  apiClient()
    .put(`/api/payments/archive`, data)
    .then((response) => {
      dispatch({
        type: UPDATE_PAYMENT,
        payload: response.data.result,
      });
      dispatch(
        addFlashMessages({
          type: "success",
          eventFor: "reseller_payment",
          text: response.data.message,
        })
      );
    })
    .catch((err) => {
      typeof err.response !== "undefined" &&
        dispatch({ type: GET_ERRORS, payload: err.response.data });
    });
};

export const walletCredits = () => (dispatch) => {
  dispatch(setWalletLoading());
  apiClient()
    .get("/api/wallet/get")
    .then((res) => {
      dispatch({
        type: GET_WALLET_AMOUNT,
        payload: res.data[0].credits,
      });
      dispatch(setPaymentLoading("false"));
    })
    .catch((err) => {
      dispatch({
        type: GET_WALLET_AMOUNT,
        payload: "0",
      });
      typeof err.response !== "undefined" &&
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      dispatch(setWalletLoading("false"));
    });
};

export const setWalletLoading = (show = "true") => {
  return {
    type: WALLET_LOADING,
    payload: show,
  };
};

export const setPaymentLoading = (show = "true") => {
  return {
    type: PAYMENT_LOADING,
    payload: show,
  };
};
