import React, { Component } from "react";
import PropTypes from "prop-types";
import FlashMessage from "./FlashMessage";

class FlashMessageList extends Component {
  render() {
    let messages = this.props.messages;
    if (typeof messages !== "undefined" && messages.length > 0) {
      messages = this.props.messages.map((message) => (
        <FlashMessage key={message.id} message={message} />
      ));
    } else {
      messages = "";
    }

    return (
      <div>
        <div>{messages}</div>
      </div>
    );
  }
}

FlashMessageList.propTypes = {
  messages: PropTypes.array.isRequired,
};

export default FlashMessageList;
