import { FILTER_PAYMENTS_BY_ARCHIVE_STATUS } from "actions/actionTypes";
import { UPDATE_PAYMENT } from "actions/actionTypes";
import { ADMIN_LIST_PAYMENTS } from "actions/actionTypes";
import { PAYMENT_LOADING } from "actions/actionTypes";
import { LIST_PAYMENTS } from "actions/actionTypes";
import { CREATE_PAYMENT } from "actions/actionTypes";

const initialState = {
  payments: [],
  adminPayments: [],
  loading: false,
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_PAYMENTS:
      return {
        ...state,
        payments: action.payload,
        loading: false,
      };
    case ADMIN_LIST_PAYMENTS:
      return {
        ...state,
        adminPayments: action.payload,
        loading: false,
      };
    case CREATE_PAYMENT:
      return {
        ...state,
        payments: [action.payload, ...state.payments],
        loading: false,
      };
    case UPDATE_PAYMENT:
      const updatedItems = state.payments.map((item) => {
        if (item.id === action.payload.id) {
          return { ...action.payload };
        }
        return item;
      });
      return {
        ...state,
        payments: updatedItems,
        loading: false,
      };
    case FILTER_PAYMENTS_BY_ARCHIVE_STATUS:
      return {
        ...state,
        payments: state.payments.filter(
          (item) => item.is_archived === action.filter
        ),
      };
    case PAYMENT_LOADING:
      return {
        ...state,
        loading: action.payload === "false" ? false : true,
      };
    default:
      return state;
  }
};

export default paymentReducer;
