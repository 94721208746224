import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import { Button, ClosingAlert, Icon } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import Loader from "components/Loader";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearFlashMessages } from "actions/flashMessagesAction";
import { useEffect } from "react";
import FlashMessageList from "components/flash/FlashMessageList";
import { archive_payment } from "actions/paymentAction";
import { resetErrors } from "actions/flashMessagesAction";
import moment from "moment";

export default function PaymentCardTable(props) {
  const { flash } = useSelector((state) => state.flash);
  const [flashMsg, setFlashMsg] = useState([]);
  const [tableView, setTableView] = useState("0");
  const errorMessage = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  const clearFlash = () => {
    setTimeout(() => {
      dispatch(clearFlashMessages());
      setFlashMsg([]);
    }, 10000);
  };
  useEffect(() => {
    dispatch(resetErrors());
    if (flash && flash.length > 0) {
      setFlashMsg(flash);
      clearFlash();
    }
  }, [flash]);

  function handleChange(e) {
    setTableView(e.target.value);
  }

  const archiveNow = ({ id, val }) => {
    dispatch(archive_payment({ id, is_archived: val }));
  };

  const { payments, loading } = props;
  console.log(payments);
  let setContent;
  if (loading) {
    setContent = (
      <tr>
        <td colSpan="6">
          <Loader />
        </td>
      </tr>
    );
  } else if (payments.length === 0) {
    setContent = (
      <tr>
        <th
          colSpan="6"
          className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-center"
        >
          No records found.
        </th>
      </tr>
    );
  } else {
    setContent = payments
      .filter((v) =>
        tableView !== "2"
          ? typeof v.is_archived !== "undefined" &&
            v.is_archived.toString() === tableView
          : v
      )
      .map((item) => (
        <tr key={item.id}>
          <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
            {moment(item.created_at).format("MM/DD/YYYY hh:mm A")}
          </th>
          <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
            {item.email && item.email}
          </th>
          <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
            {item.payment_type === "credit_purchase" && "Credit Purchase"}
          </th>
          <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
            ${item.amount}
          </th>
          <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
            {item.status === "succeeded" ? (
              <span style={{ color: "green", fontStyle: "italic" }}>
                Succeeded
              </span>
            ) : (
              <span style={{ color: "red", fontStyle: "italic" }}>
                {item.status}
              </span>
            )}
          </th>
          <th className="flex border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
            {item.is_archived.toString() === "0" ? (
              <Button
                color="red"
                buttonType="filled"
                size="sm"
                rounded={true}
                block={false}
                iconOnly={true}
                ripple="light"
                onClick={() => archiveNow({ id: item.id, val: "1" })}
                title="Archive this Payment"
              >
                <Icon name="archive" size="sm" />
              </Button>
            ) : (
              <Button
                color="yellow"
                buttonType="filled"
                size="sm"
                rounded={true}
                block={false}
                iconOnly={true}
                ripple="light"
                onClick={() => archiveNow({ id: item.id, val: "0" })}
                title="Unarchive this Payment"
              >
                <Icon name="unarchive" size="sm" />
              </Button>
            )}
          </th>
        </tr>
      ));
  }

  return (
    <Card>
      <CardHeader color="orange" contentPosition="left" className="flexIt">
        <h2 className="text-white text-2xl">Payment log</h2>
        <div style={{ fontSize: "17px", fontStyle: "italic" }}>
          <input
            type="radio"
            name="view"
            value="2"
            style={{
              height: "18px",
              width: "18px",
              verticalAlign: "text-top",
              cursor: "pointer",
            }}
            checked={tableView === "2"}
            onChange={handleChange}
          />
          <span style={{ marginRight: "10px" }}>All</span>
          <input
            type="radio"
            name="view"
            value="1"
            style={{
              height: "18px",
              width: "18px",
              verticalAlign: "text-top",
              cursor: "pointer",
            }}
            checked={tableView === "1"}
            onChange={handleChange}
          />
          <span style={{ marginRight: "10px" }}>Archived</span>
          <input
            type="radio"
            name="view"
            value="0"
            style={{
              height: "18px",
              width: "18px",
              verticalAlign: "text-top",
              cursor: "pointer",
            }}
            checked={tableView === "0"}
            onChange={handleChange}
          />
          <span style={{ marginRight: "10px" }}>Unarchived</span>
        </div>

        <Link
          className="false flex items-center justify-center gap-1 font-bold outline-none uppercase tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-2.5 px-6 text-xs leading-normal bg-transparent border border-solid shadow-none text-white-500 border-white-500 hover:bg-white-50 hover:border-white-700 hover:text-white-700 hover:bg-white-50 active:bg-white-100"
          to="/payments/new"
        >
          Buy Credits
        </Link>
      </CardHeader>
      <CardBody>
        <div className="overflow-x-auto">
          {errorMessage.message && (
            <ClosingAlert color="red">{errorMessage.message}</ClosingAlert>
          )}
          {flashMsg && (
            <FlashMessageList
              messages={
                Array.isArray(flashMsg)
                  ? flashMsg.filter((f) => f.eventFor === "reseller_payment")
                  : {}
              }
            />
          )}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr key="head">
                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Created At
                </th>
                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Email
                </th>
                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Payment Type
                </th>
                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Amount
                </th>
                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Status
                </th>
                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>{setContent}</tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
}
