import { list_members } from "actions/memberActions";
import MemberCardTable from "components/member/MemberTableCard";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

export default function Users() {
  const { members, loading } = useSelector((state) => state.members);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(list_members());
  }, [dispatch]);

  console.log(loading);

  const props = {
    members,
    loading,
    name: "Users",
    link: "/user/new",
  };
  return (
    <>
      <div className="bg-light-blue-500 pt-14 pb-28 px-3 md:px-8 h-auto">
        <div className="container mx-auto max-w-full"></div>
      </div>

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <MemberCardTable {...props} />
          </div>
        </div>
      </div>
    </>
  );
}
