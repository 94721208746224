import { ADMIN_AUTH_LOADING } from "actions/actionTypes";
import { SET_CURRENT_ADMIN } from "actions/actionTypes";
import isEmpty from "utils/isEmpty";

const initialState = {
  isAuthenticated: false,
  admin: {},
  loading: false,
};

const adminAuthReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_ADMIN:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        admin: action.payload,
        loading: false,
      };
    case ADMIN_AUTH_LOADING:
      return {
        ...state,
        loading: action.payload === "false" ? false : true,
      };
    default:
      return state;
  }
};

export default adminAuthReducer;
