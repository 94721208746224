import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import H5 from "@material-tailwind/react/Heading5";
import InputIcon from "@material-tailwind/react/InputIcon";
import Button from "@material-tailwind/react/Button";
import Page from "components/login/Page";
import Container from "components/login/Container";
import Loader from "components/Loader";
import { Link } from "react-router-dom";
import FlashMessageList from "components/flash/FlashMessageList";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { forgot_password } from "actions/authActions";

export default function ForgotPassword() {
  const { flash } = useSelector((state) => state.flash);
  const { loading } = useSelector((state) => state.auth);
  const [flashMsg, setFlashMsg] = useState([]);
  const errorsSubmission = useSelector((state) => state.errors);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    if (flash && flash.length > 0) {
      setEmail("");
      setFlashMsg(flash);
    }
    if (errorsSubmission) {
      setErrors(errorsSubmission);
    }
  }, [errorsSubmission, flash]);

  let loader;
  if (loading) {
    loader = <Loader />;
  }

  const saveForm = async (e) => {
    e.preventDefault();
    const data = { email };
    dispatch(forgot_password(data));
  };

  return (
    <Page>
      <Container>
        <Card>
          <CardHeader color="lightBlue">
            <H5 color="white" style={{ marginBottom: 0 }}>
              Forgot Password
            </H5>
          </CardHeader>

          <CardBody>
            <form onSubmit={saveForm}>
              <div className="px-2 bg-bb mb-8">
                <InputIcon
                  type="email"
                  value={email}
                  color="lightBlue"
                  placeholder="Email Address"
                  iconName="email"
                  onChange={(e) => setEmail(e.target.value)}
                  error={
                    typeof errors.errors !== "undefined" &&
                    typeof errors.errors.email !== "undefined"
                      ? errors.errors.email[0]
                      : ""
                  }
                />
              </div>

              <div className="flex justify-center mb-4 bg-bb">
                <Button
                  color="lightBlue"
                  buttonType="outline"
                  size="sm"
                  type="submit"
                  ripple="dark"
                >
                  Send Password Reset Link
                </Button>
              </div>
              <Link to="/" className="loginLinks">
                Login here {">>"}
              </Link>
              {
                <p style={{ color: "red", fontStyle: "italic" }}>
                  {typeof errors.eMessage !== "undefined"
                    ? errors.eMessage
                    : ""}
                </p>
              }
              {flashMsg && (
                <FlashMessageList
                  messages={
                    Array.isArray(flashMsg)
                      ? flashMsg.filter((f) => f.eventFor === "reseller_forgot")
                      : {}
                  }
                />
              )}
              {loader && loader}
            </form>
          </CardBody>
        </Card>
      </Container>
    </Page>
  );
}
