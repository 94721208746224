import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
//import CardFooter from "@material-tailwind/react/CardFooter";
import H5 from "@material-tailwind/react/Heading5";
import InputIcon from "@material-tailwind/react/InputIcon";
import Button from "@material-tailwind/react/Button";
import Page from "components/login/Page";
import Container from "components/login/Container";
import Loader from "components/Loader";
import { Link } from "react-router-dom";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import FlashMessageList from "components/flash/FlashMessageList";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { reset_password } from "actions/authActions";

export default function ResetPassword() {
  const { token, emailId } = useParams();
  let history = useHistory();

  const { flash } = useSelector((state) => state.flash);
  const { loading } = useSelector((state) => state.auth);
  const [flashMsg, setFlashMsg] = useState([]);
  const errorsSubmission = useSelector((state) => state.errors);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();
  useEffect(() => {
    if (flash && flash.length > 0) {
      setFlashMsg(flash);
    }
    if (errorsSubmission) {
      setErrors(errorsSubmission);
    }
    if (emailId) {
      setEmail(emailId);
    }
  }, [errorsSubmission, flash, emailId]);

  let loader;
  if (loading) {
    loader = <Loader />;
  }

  const resetPass = async (e) => {
    e.preventDefault();
    if (token) {
      const data = {
        email,
        password,
        password_confirmation,
        token,
      };
      console.log("data = ", data);
      dispatch(reset_password(data, history));
    } else {
      window.alert("Token is not set!");
    }
  };

  return (
    <Page>
      <Container>
        <Card>
          <CardHeader color="lightBlue">
            <H5 color="white" style={{ marginBottom: 0 }}>
              Reset Password
            </H5>
          </CardHeader>

          <CardBody>
            <form onSubmit={resetPass}>
              <div className="mb-8 px-4 bg-bb">
                <InputIcon
                  type="email"
                  color="lightBlue"
                  placeholder="Email Address"
                  iconName="email"
                  value={email && email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={
                    typeof errors.errors !== "undefined" &&
                    typeof errors.errors.email !== "undefined"
                      ? errors.errors.email[0]
                      : ""
                  }
                />
              </div>
              <div className="mb-8 px-4">
                <InputIcon
                  type="password"
                  color="lightBlue"
                  placeholder="Password"
                  iconName="lock"
                  error={
                    typeof errors.errors !== "undefined" &&
                    typeof errors.errors.password !== "undefined"
                      ? errors.errors.password[0]
                      : ""
                  }
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="px-4 mb-8">
                <InputIcon
                  type="password"
                  color="lightBlue"
                  placeholder="Confirm Password"
                  iconName="lock"
                  error={
                    typeof errors.errors !== "undefined" &&
                    typeof errors.errors.password !== "undefined"
                      ? errors.errors.password[0]
                      : ""
                  }
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                />
              </div>

              <div className="flex justify-center mb-4 bg-bb">
                <Button
                  color="lightBlue"
                  buttonType="outline"
                  size="sm"
                  ripple="dark"
                >
                  Reset Password
                </Button>
              </div>
              <Link to="/" className="loginLinks">
                Login here {">>"}
              </Link>
              {
                <p style={{ color: "red", fontStyle: "italic" }}>
                  {typeof errors.message !== "undefined" ? errors.message : ""}
                </p>
              }
              {
                <p style={{ color: "red", fontStyle: "italic" }}>
                  {typeof errors.tokenError !== "undefined"
                    ? errors.tokenError
                    : ""}
                </p>
              }
              {flashMsg && (
                <FlashMessageList
                  messages={
                    Array.isArray(flashMsg)
                      ? flashMsg.filter((f) => f.eventFor === "reset_pass")
                      : {}
                  }
                />
              )}
              {loader && loader}
            </form>
          </CardBody>
        </Card>
      </Container>
    </Page>
  );
}
