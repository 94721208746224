import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Button from "@material-tailwind/react/Button";
import Input from "@material-tailwind/react/Input";
import { Link, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Loader";
import { resetErrors } from "actions/flashMessagesAction";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { get_reseller_by_id } from "actions/adminResellerAction";
import { create_reseller } from "actions/adminResellerAction";
import { update_reseller } from "actions/adminResellerAction";

const CreateEditResellerForm = () => {
  const { id } = useParams();
  let history = useHistory();

  const { loading, reseller, resellers } = useSelector(
    (state) => state.adminResellers
  );
  const errorsSubmission = useSelector((state) => state.errors);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [status, setStatus] = useState("");
  const [discount, setDiscount] = useState("");
  const [errors, setErrors] = useState({});
  const [credits, setCredits] = useState("");
  const [avCredits, setAvCredits] = useState("0");
  const [reset, setReset] = useState("0");

  const dispatch = useDispatch();
  const get_reseller = () => dispatch(get_reseller_by_id(id, history));

  useEffect(() => {
    if (errorsSubmission) {
      setErrors(errorsSubmission);
    }
    if (id) {
      get_reseller();
    }

    const dex = resellers.filter((item) => item.id == id);
    console.log(dex);
    if (dex[0]) {
      setName(dex[0].name ? dex[0].name : "");
      setEmail(dex[0].email ? dex[0].email : "");
      setPassword(dex[0].password ? dex[0].password : "");
      setPhone(dex[0].phone ? dex[0].phone : "");
      setAddress(dex[0].address ? dex[0].address : "");
      setCity(dex[0].city ? dex[0].city : "");
      setState(dex[0].state ? dex[0].state : "");
      setStatus(dex[0].status ? dex[0].status : "");
      setDiscount(dex[0].discountPer ? dex[0].discountPer : "");
      setAvCredits(dex[0].credit ? dex[0].credit.credits : "0");
    } else if (id && reseller.length > 0) {
      setName(reseller[0].name ? reseller[0].name : "");
      setEmail(reseller[0].email ? reseller[0].email : "");
      setPassword(reseller[0].password ? reseller[0].password : "");
      setPhone(reseller[0].phone ? reseller[0].phone : "");
      setAddress(reseller[0].address ? reseller[0].address : "");
      setCity(reseller[0].city ? reseller[0].city : "");
      setState(reseller[0].state ? reseller[0].state : "");
      setStatus(reseller[0].status ? reseller[0].status : "");
      setDiscount(reseller[0].discountPer ? reseller[0].discountPer : "");
      setAvCredits(reseller[0].credit ? reseller[0].credit.credits : "0");
    }
  }, [errorsSubmission, id, dispatch, reseller.length]);

  useEffect(() => {
    dispatch(resetErrors());
  }, []);

  let loader;
  if (loading) {
    loader = <Loader />;
  }

  const saveForm = async (e) => {
    e.preventDefault();
    const data = {
      id: id ? id : "",
      name,
      email,
      password,
      password_confirmation,
      phone,
      address,
      city,
      state,
      status,
      credits,
      discount,
      reset,
    };
    if (credits !== "") {
      const confirmBox = window.confirm(
        `Are you sure you want to add these $${credits} credits, please verify?`
      );
      if (confirmBox === true) {
        !id
          ? dispatch(create_reseller(data, history))
          : dispatch(update_reseller(data, history));
      }
    } else {
      !id
        ? dispatch(create_reseller(data, history))
        : dispatch(update_reseller(data, history));
    }
  };
  const restCredits = () => {
    const confirmBox = window.confirm(
      `Are you sure you want to remove these $${avCredits} credits, please verify? Also make sure you hit save in order to finialize your action!`
    );
    if (confirmBox === true) {
      setAvCredits("0");
      setCredits("");
      setReset("1");
    }
  };

  return (
    <Card>
      <CardHeader color="purple" contentPosition="none">
        <div className="w-full flex items-center justify-between">
          <h2 className="text-white text-2xl">
            {!id ? "Create Reseller" : "Edit Reseller"}
          </h2>
          <Link
            to="/master/resellers"
            style={{
              padding: "5px 15px",
              border: "1px solid",
              borderRadius: "5px",
            }}
          >
            All Resellers
          </Link>
        </div>
      </CardHeader>
      <CardBody>
        <form onSubmit={saveForm}>
          <h6 className="text-purple-500 text-sm mt-3 mb-6 font-light uppercase">
            Reseller Information
          </h6>
          <div className="flex flex-wrap mt-10">
            <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="Name"
                value={name && name}
                onChange={(e) => setName(e.target.value)}
                error={
                  typeof errors.errors !== "undefined" &&
                  typeof errors.errors.name !== "undefined"
                    ? errors.errors.name[0]
                    : ""
                }
              />
            </div>
            <div className="w-full lg:w-6/12 pl-4 mb-10 font-light">
              <Input
                type="email"
                color="purple"
                placeholder="Email Address"
                value={email && email}
                onChange={(e) => setEmail(e.target.value)}
                error={
                  typeof errors.errors !== "undefined" &&
                  typeof errors.errors.email !== "undefined"
                    ? errors.errors.email[0]
                    : ""
                }
              />
            </div>
            <div className="w-full lg:w-6/12 mb-10 font-light">
              <Input
                color="purple"
                placeholder="Password"
                type="password"
                value={password && password}
                error={
                  typeof errors.errors !== "undefined" &&
                  typeof errors.errors.password !== "undefined"
                    ? errors.errors.password[0]
                    : ""
                }
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="w-full lg:w-6/12 pl-4 mb-10 font-light">
              <Input
                color="purple"
                placeholder="Confirm Password"
                type="password"
                value={password_confirmation && password_confirmation}
                error={
                  typeof errors.errors !== "undefined" &&
                  typeof errors.errors.password !== "undefined"
                    ? errors.errors.password[0]
                    : ""
                }
                onChange={(e) => setPasswordConfirmation(e.target.value)}
              />
            </div>
          </div>

          <h6 className="text-purple-500 text-sm my-6 font-light uppercase">
            Contact Information
          </h6>
          <div className="flex flex-wrap mt-10">
            <div className="w-full lg:w-12/12 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="Address"
                value={address && address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="w-full lg:w-4/12 pr-4 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="Phone"
                value={phone && phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="w-full lg:w-4/12 px-4 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="City"
                value={city && city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div className="w-full lg:w-4/12 pl-4 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="State"
                value={state && state}
                onChange={(e) => setState(e.target.value)}
              />
            </div>
            {/* <div className="w-full lg:w-3/12 pl-4 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="Zip"
                value={zip && zip}
                onChange={(e) => setZip(e.target.value)}
              />
            </div> */}
          </div>
          <h6 className="text-purple-500 text-sm my-6 font-light uppercase">
            Credits Information
          </h6>
          <div className="flex flex-wrap mt-10">
            <div className="w-full lg:w-4/12 mb-4 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="Assign Dollar Credits"
                value={credits && credits}
                onChange={(e) => setCredits(e.target.value)}
              />
              {id && (
                <Button
                  buttonType="outline"
                  type="button"
                  color="purple"
                  className="mt-2"
                  onClick={restCredits}
                >
                  Reset Credits
                </Button>
              )}
            </div>
            <div className="w-full lg:w-4/12 mb-10 font-light">
              <span style={{ fontStyle: "italic", color: "red" }}>
                Credits Available: ${avCredits && avCredits}
              </span>
            </div>
          </div>
          <div className="flex flex-wrap mt-2">
            <div className="w-full lg:w-4/12 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="Discount %"
                value={discount && discount}
                onChange={(e) => setDiscount(e.target.value)}
              />
            </div>
            <div className="w-full lg:w-4/12 mb-10 font-light">
              <span style={{ fontStyle: "italic", color: "red" }}>
                Default is : 50%
              </span>
            </div>
          </div>
          <h6 className="text-purple-500 text-sm my-6 font-light uppercase">
            Status Information
          </h6>
          <div className="flex flex-wrap mt-10">
            <div className="w-full lg:w-6/12 mb-10 font-light">
              <div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={status === "active"}
                    value="active"
                    onChange={(e) => setStatus(e.target.value)}
                  />
                  <input type="hidden" name="personal_price" value="99" />
                  <label
                    className="form-check-label inline-block text-gray-800"
                    htmlFor="inlineRadio10"
                  >
                    Active
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="inactive"
                    checked={status === "inactive"}
                    onChange={(e) => setStatus(e.target.value)}
                  />
                  <input type="hidden" name="personal_plus_price" value="197" />
                  <label
                    className="form-check-label inline-block text-gray-800"
                    htmlFor="inlineRadio20"
                  >
                    Inactive
                  </label>
                </div>
              </div>
            </div>
          </div>

          <Button
            color="purple"
            buttonType="filled"
            size="regular"
            rounded={false}
            block={false}
            iconOnly={false}
            ripple="light"
          >
            Submit
          </Button>
          {loader && loader}
        </form>
      </CardBody>
    </Card>
  );
};

export default CreateEditResellerForm;
