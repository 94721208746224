import { Route, Redirect } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const Auth = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) =>
        Auth.isResellerAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  Auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  Auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
