import ClosingAlert from "@material-tailwind/react/ClosingAlert";

const FlashMessage = (props) => {
  const { type, text } = props.message;
  let color = "";
  if (type === "success") {
    color = "green";
  } else if (type === "error") {
    color = "red";
  }
  return <ClosingAlert color={color}>{text}</ClosingAlert>;
};

export default FlashMessage;
