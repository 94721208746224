import { WALLET_LOADING } from "actions/actionTypes";
import { ADD_WALLET_AMOUNT } from "actions/actionTypes";
import { GET_WALLET_AMOUNT } from "actions/actionTypes";

const initialState = {
  walletAmount: "",
  loading: false,
};

const walletReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WALLET_AMOUNT:
      return {
        ...state,
        walletAmount: action.payload,
        loading: false,
      };
    case ADD_WALLET_AMOUNT:
      return {
        ...state,
        walletAmount: action.payload,
        loading: false,
      };
    case WALLET_LOADING:
      return {
        ...state,
        loading: action.payload === "false" ? false : true,
      };
    default:
      return state;
  }
};

export default walletReducer;
