import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import CardFooter from "@material-tailwind/react/CardFooter";
import H5 from "@material-tailwind/react/Heading5";
import InputIcon from "@material-tailwind/react/InputIcon";
import Button from "@material-tailwind/react/Button";
import DefaultNavbar from "components/DefaultNavbar";

import Container from "components/login/Container";
import { login } from "actions/adminAuthActions";
import Loader from "components/Loader";
import { resetErrors } from "actions/flashMessagesAction";

export default function AdminLogin({ history }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});

  const { isAuthenticated, loading } = useSelector((state) => state.adminAuth);
  const errorsLogin = useSelector((state) => state.errors);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/master/dashboard");
    }
    if (errorsLogin) {
      setErrors(errorsLogin);
    }
  }, [isAuthenticated, errorsLogin, history]);

  useEffect(() => {
    dispatch(resetErrors());
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    dispatch(login({ email, password }, history));
  };

  return (
    <div className="bg-adminlogin-background bg-cover bg-center w-screen h-screen relative flex flex-col justify-between">
      <DefaultNavbar comp="login" />
      <Container>
        <Card>
          <CardHeader color="lightBlue">
            <H5 color="white" style={{ marginBottom: 0 }}>
              Administrator Login
            </H5>
          </CardHeader>
          <form onSubmit={submitHandler}>
            <CardBody>
              <div className="mb-12 px-4 bg-bb">
                <InputIcon
                  type="email"
                  color="lightBlue"
                  placeholder="Email Address"
                  iconName="email"
                  onChange={(e) => setEmail(e.target.value)}
                  error={
                    typeof errors.errors !== "undefined" &&
                    typeof errors.errors.email !== "undefined"
                      ? errors.errors.email[0]
                      : ""
                  }
                />
              </div>
              <div className="mb-3 px-4">
                <InputIcon
                  type="password"
                  color="lightBlue"
                  placeholder="Password"
                  iconName="lock"
                  onChange={(e) => setPassword(e.target.value)}
                  error={
                    typeof errors.errors !== "undefined" &&
                    typeof errors.errors.password !== "undefined"
                      ? errors.errors.password[0]
                      : ""
                  }
                />
              </div>
              {/* <div className="mb-4 px-4">
              <Checkbox color="lightBlue" text="Remember Me" id="remember" />
            </div> */}
              <span className="flex items-center font-medium tracking-wide text-red-500 text-sm">
                {errors.message && (
                  <label
                    className="error mt-2 text-danger"
                    style={{ fontSize: "13px" }}
                  >
                    {errors.message}
                  </label>
                )}
              </span>
            </CardBody>
            <CardFooter>
              <div className="flex justify-center bg-bb">
                {loading && <Loader />}
                <Button
                  color="lightBlue"
                  buttonType="link"
                  size="lg"
                  ripple="dark"
                >
                  Login
                </Button>
              </div>
            </CardFooter>
          </form>
        </Card>
      </Container>
    </div>
  );
}
