import { ADD_FLASH_MESSAGE, DELETE_FLASH_MESSAGE } from "actions/actionTypes";
import shortid from "shortid";

const initialState = [];

const errorReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_FLASH_MESSAGE:
      return {
        ...state,
        flash: [
          {
            id: shortid.generate(),
            type: action.message.type,
            eventFor:
              typeof action.message.eventFor !== "undefined" &&
              action.message.eventFor,
            text: action.message.text,
          },
        ],
      };
    case DELETE_FLASH_MESSAGE:
      return {
        ...state,
        flash: [],
      };
    default:
      return state;
  }
};

export default errorReducer;
