import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  InputIcon,
} from "@material-tailwind/react";
import { create_payment } from "actions/paymentAction";
import Loader from "components/Loader";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { setPaymentLoading } from "actions/paymentAction";
import SignatureCanvas from "react-signature-canvas";
import "./sigCanvas.css";
import axios from "axios";

const NewPaymentForm = () => {
  const { reseller } = useSelector((state) => state.auth);
  const Loading = useSelector((state) => state.payments.loading);
  const errorsSubmission = useSelector((state) => state.errors);

  const [resellerId, setResellerId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [errors, setErrors] = useState({});
  const [ip, setIP] = useState("");

  const history = useHistory();
  const sigCanvas = useRef({});
  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useDispatch();

  const getIp = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };

  useEffect(() => {
    getIp();
    if (reseller.id) {
      setResellerId(reseller.id);
      setName(reseller.name);
      setEmail(reseller.email);
      setPhone(reseller.phone);
      setState(reseller.state);
      setCity(reseller.city);
      setAddress(reseller.address);
    }
    if (errorsSubmission) {
      setErrors(errorsSubmission);
    }
  }, [
    reseller.id,
    reseller.name,
    reseller.email,
    reseller.phone,
    reseller.state,
    reseller.city,
    reseller.address,
    errorsSubmission,
    //clearFlash,
  ]);

  const clear = () => sigCanvas.current.clear();
  const isEmpty = () => sigCanvas.current.isEmpty();

  let loader;
  if (Loading) {
    loader = <Loader />;
  } else {
    loader = "";
  }
  //console.log(ip);
  const saveForm = async (e) => {
    e.preventDefault();
    if (isEmpty()) {
      alert("Please add a signature");
      // Signature has not been added.
      return;
    }
    const sigi = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    //console.log(sigi);
    if (!stripe || !elements) {
      alert("Stripe is not loaded yet. Please refresh the page and try again.");
      // Stripe.js has not yet loaded.
      return;
    }
    const confirmBox = window.confirm(
      "Buying of credits is an irreversible process and will not be refunded in case amount is deducted, however the amount will be reflected as credits in your account. Are you want to proceed?"
    );
    if (confirmBox === true) {
      dispatch(setPaymentLoading());
      const paymentMethodObj = {
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          name,
          email,
          phone,
          address: {
            state,
            city,
            line1: address,
          },
        },
      };
      const paymentMethodResult = await stripe.createPaymentMethod(
        paymentMethodObj
      );
      dispatch(
        create_payment(
          {
            result: paymentMethodResult,
            amount: amount,
            state,
            city,
            address,
            ip,
            sigImage: sigi,
          },
          history
        )
      );
    }
  };

  const amountCheckAndSet = (e) => {
    const re = /^[0-9\b]+$/;

    if (e.target.value === "" || re.test(e.target.value)) {
      setAmount(e.target.value);
    }
  };

  return (
    <Card>
      <CardHeader color="purple" contentPosition="none">
        <div className="w-full flex items-center justify-between">
          <h2 className="text-white text-2xl">By Credits</h2>
          <Link
            to="/payments"
            style={{
              padding: "5px 15px",
              border: "1px solid",
              borderRadius: "5px",
            }}
          >
            All Payments
          </Link>
        </div>
      </CardHeader>
      <CardBody>
        <form onSubmit={saveForm}>
          <input type="hidden" name="id" value={resellerId} />

          <h6 className="text-purple-500 text-sm mt-3 mb-6 font-light uppercase">
            User Information
          </h6>
          <div className="flex flex-wrap mt-10">
            <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="Name"
                value={name ? name : ""}
                onChange={(e) => setName(e.target.value)}
                error={
                  typeof errors.errors !== "undefined" &&
                  typeof errors.errors.name !== "undefined"
                    ? errors.errors.name[0]
                    : ""
                }
              />
            </div>
            <div className="w-full lg:w-6/12 pl-4 mb-10 font-light">
              <Input
                type="email"
                color="purple"
                placeholder="Email Address"
                value={email ? email : ""}
                onChange={(e) => setEmail(e.target.value)}
                error={
                  typeof errors.errors !== "undefined" &&
                  typeof errors.errors.email !== "undefined"
                    ? errors.errors.email[0]
                    : ""
                }
              />
            </div>
          </div>

          <h6 className="text-purple-500 text-sm my-6 font-light uppercase">
            Billing Information
          </h6>
          <div className="flex flex-wrap mt-10">
            <div className="w-full lg:w-12/12 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="Address"
                value={address ? address : ""}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="w-full lg:w-4/12 pr-4 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="Phone"
                value={phone ? phone : ""}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="w-full lg:w-4/12 px-4 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="City"
                value={city ? city : ""}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div className="w-full lg:w-4/12 pl-4 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="State"
                value={state ? state : ""}
                onChange={(e) => setState(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-wrap mt-10">
            <div className="w-full lg:w-6/12 mb-10 font-light">
              <InputIcon
                type="text"
                color="purple"
                placeholder="Add amount in decimal whole number only"
                iconFamily="material-icons"
                iconName="paid"
                value={amount ? amount : ""}
                onChange={amountCheckAndSet}
              />
            </div>
          </div>
          <h6 className="text-purple-500 text-sm my-6 font-light uppercase">
            Card Details
          </h6>
          {/* <div className="flex flex-wrap mt-10">
            <div className="w-full lg:w-6/12 mb-10 font-light">
              <CardNumberElement
                id="cc-number"
                className=""
                options={CARD_ELEMENT_OPTIONS}
              />
            </div>
          </div>
          <div className="flex flex-wrap mt-10">
            <div className="w-full lg:w-3/12 mb-10 font-light">
              <CardExpiryElement
                id="expiry"
                className="form-control"
                options={CARD_ELEMENT_OPTIONS}
              />
            </div>
            <div className="w-full lg:w-3/12 mb-10 font-light">
              <CardCvcElement
                id="cvc"
                className="form-control"
                options={CARD_ELEMENT_OPTIONS}
              />
            </div>
          </div> */}
          <div className="w-full lg:w-6/12 mb-10 font-light">
            <CardElement />
            {(typeof errors !== "undefined" &&
              errors.code === "incomplete_number") ||
            errors.code === "invalid_number" ? (
              <span style={{ color: "red", fontSize: "13px" }}>
                {errors.message}
              </span>
            ) : (
              ""
            )}
          </div>

          <h6 className="text-purple-500 text-sm my-6 font-light uppercase">
            Amount Information
          </h6>
          <div
            className="w-96 mb-6 bg-white border-t border-r rounded-5"
            style={{ borderLeftWidth: "1px" }}
          >
            <div className="overflow-x-auto">
              <table className="items-center w-full bg-transparent border-collapse">
                <tbody>
                  <tr className="bg-white border-b">
                    <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                      Product
                    </th>
                    <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                      Dollar Credits
                    </th>
                  </tr>
                  <tr className="bg-gray-100 border-b">
                    <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                      Price
                    </th>
                    <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                      $ {amount ? amount : ""} USD
                    </th>
                  </tr>
                  <tr className="bg-white border-b">
                    <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                      Total Payable
                    </th>
                    <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                      $ {amount && amount} USD
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <h6 className="text-purple-500 text-sm my-6 font-light uppercase">
            Authorize Credit Card Payment
          </h6>
          <div className="w-full lg:w-6/12 mb-10 font-light">
            <p>
              The Service is billed in advance on a monthly basis and is
              nonrefundable. There will be no refunds or credits for partial
              months of service, upgrade/downgrade refunds, or refunds for
              months unused with an open account.
            </p>

            <p
              style={{
                fontWeight: "bold",
                paddingTop: "10px",
                paddingBottom: "5px",
              }}
            >
              Enter Digital Signature
            </p>
            <SignatureCanvas
              ref={sigCanvas}
              canvasProps={{
                className: "signatureCanvas",
              }}
            />
            <div style={{ display: "flex" }}>
              {/* <Button
                color="lightBlue"
                buttonType="outline"
                className="mt-2"
                type="button"
                size="sm"
                rounded={false}
                block={false}
                iconOnly={false}
                ripple="dark"
                onClick={save}
              >
                Save
              </Button> */}
              <Button
                color="lightBlue"
                buttonType="outline"
                className="mt-2"
                type="button"
                size="sm"
                rounded={false}
                block={false}
                iconOnly={false}
                ripple="dark"
                onClick={clear}
              >
                Clear
              </Button>
            </div>
          </div>
          <Button
            color="purple"
            buttonType="filled"
            size="regular"
            rounded={false}
            block={false}
            iconOnly={false}
            ripple="light"
          >
            Buy Now
          </Button>
          {loader && loader}
        </form>
      </CardBody>
    </Card>
  );
};

export default NewPaymentForm;
