import React from "react";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import FlashMessageList from "components/flash/FlashMessageList";

const CreateEmbeddSuccess = () => {
  const { flash } = useSelector((state) => state.flash);
  const [flashMsg, setFlashMsg] = useState([]);
  useEffect(() => {
    if (flash && flash.length > 0) {
      setFlashMsg(flash);
    }
  }, [flash]);
  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 embeddTransY mb-4">
      <Card className="mb-5">
        <CardHeader color="purple" contentPosition="none">
          <div className="w-full flex items-center justify-between">
            <h2 className="text-white text-2xl">User SignUp Form</h2>
          </div>
        </CardHeader>
        <CardBody>
          {flashMsg && (
            <FlashMessageList
              messages={
                Array.isArray(flashMsg)
                  ? flashMsg.filter(
                      (f) =>
                        f.eventFor === "member_update" ||
                        f.eventFor === "member_create"
                    )
                  : {}
              }
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default CreateEmbeddSuccess;
